import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { SidebarService, ISidebar } from './sidebar.service';
import menuItems, { IMenuItem } from 'src/app/constants/menu';
import { Subscription } from 'rxjs';
import { UserRole } from 'src/app/shared/auth.roles';
import { AuthService } from 'src/app/shared/auth.service';
import {GlobalService} from "../../../shared/global.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {parse} from "jasmine-spec-reporter/built/configuration-parser";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit, OnDestroy {
  menuItems: IMenuItem[] = menuItems;

  selectedParentMenu = '';
  viewingParentMenu = '';
  currentUrl: string;

  sidebar: ISidebar;
  subscription: Subscription;
  closedCollapseList = [];

  currentUser = null;
  ownPropertyCount = localStorage.getItem('property_count')?parseInt(localStorage.getItem('property_count')):0;
  notMyHouseCount = localStorage.getItem('not_my_house_count')?parseInt(localStorage.getItem('not_my_house_count')):0;
  window_Size = window.innerWidth;
  haveSubmenu = false;
  isProduction = this.globalService.getIsProduction();
  constructor(
    private router: Router,
    private sidebarService: SidebarService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private globalService: GlobalService,
    private deviceService: DeviceDetectorService
  ) {
    this.sidebarService.propertyListMenuShowHideEvent.subscribe((res) => {

      this.menuItems.forEach((item) => {
        if(item.label == 'List Properties'){
          item.isVisible = res;
        }

        if(this.isProduction.INTEREST_RATE == true || this.isProduction.PORTFOLIO_SCENARIO_CALCULATOR == true || this.isProduction.CAN_I_AFFORD_CALCULATOR == true){
          item.isVisible = false;

          // item.subs.forEach((subItem) => {
          if(item.label == 'Interest Rate'){
            item.isVisible = !this.isProduction.INTEREST_RATE;
          }

          if(item.label == 'Portfolio Calc'){
            item.isVisible = !this.isProduction.PORTFOLIO_SCENARIO_CALCULATOR;
          }

          if(item.label == 'Can I Afford Calc'){
            item.isVisible = !this.isProduction.CAN_I_AFFORD_CALCULATOR;
          }
          // });
        }
        // if(item.label == 'Calculators'){
        //   if(this.isProduction.INTEREST_RATE == true || this.isProduction.PORTFOLIO_SCENARIO_CALCULATOR == true || this.isProduction.CAN_I_AFFORD_CALCULATOR == true){
        //     item.isVisible = false;
        //
        //     // item.subs.forEach((subItem) => {
        //       if(item.label == 'Interest Rate'){
        //         item.isVisible = !this.isProduction.INTEREST_RATE;
        //       }
        //
        //       if(item.label == 'Portfolio Calc'){
        //         item.isVisible = !this.isProduction.PORTFOLIO_SCENARIO_CALCULATOR;
        //       }
        //
        //       if(item.label == 'Can I Afford Calc'){
        //         item.isVisible = !this.isProduction.CAN_I_AFFORD_CALCULATOR;
        //       }
        //     // });
        //   }
        // }
      });
    });

    this.globalService.propertyCountChangeEvent.subscribe((data)=>{
      localStorage.setItem('property_count',data['property_count']);
      localStorage.setItem('not_my_house_count',data['not_my_house_count']);
      this.changeMenuVisibility();
    });

    this.globalService.propertyPermissionEvent.subscribe((res) => {
      let propertyPermission = res['permission_list'];
      this.menuItems.forEach((item) => {
        if(item.label == 'List Properties'){
          item.subs.forEach((subItem) => {
            propertyPermission.forEach((permission) => {
              if(subItem.label == "Snapshot"){
                subItem.isVisible = res['snapShotVisibility'];
              }
              else {
                if(subItem.label == permission.permission_form){
                  subItem.isVisible = permission.is_view == 1 ? true : false;
                }
              }
            });
          });
        }
      });
    });


    this.authService.getUser().then((user) => {
      this.currentUser = user;
    });

    this.subscription = this.sidebarService.getSidebar().subscribe(
      (res) => {
        this.sidebar = res;
      },
      (err) => {
        console.error(`An error occurred: ${err.message}`);
      }
    );
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .subscribe((event) => {
        const path = this.router.url.split('?')[0];
        const paramtersLen = Object.keys(event.snapshot.params).length;
        const pathArr = path
          .split('/')
          .slice(0, path.split('/').length - paramtersLen);
        this.currentUrl = pathArr.join('/');
      });

    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const { containerClassnames } = this.sidebar;
        this.selectMenu();
        this.toggle();
        this.sidebarService.setContainerClassnames(
          0,
          containerClassnames,
          this.sidebar.selectedMenuHasSubItems
        );
        window.scrollTo(0, 0);
      });

    this.changeMenuVisibility();
  }

  async ngOnInit(): Promise<void> {
    setTimeout(() => {
      this.selectMenu();
      const { containerClassnames } = this.sidebar;
      const nextClasses = this.getMenuClassesForResize(containerClassnames);
      this.sidebarService.setContainerClassnames(
        0,
        nextClasses.join(' '),
        this.sidebar.selectedMenuHasSubItems
      );
      this.isCurrentMenuHasSubItem();
    }, 100);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  selectMenu(): void {
    this.selectedParentMenu = this.findParentInPath(this.currentUrl) || '';
    this.isCurrentMenuHasSubItem();
  }

  findParentInPath(path): any {
    const foundedMenuItem = this.menuItems.find((x) => x.to === path);
    if (!foundedMenuItem) {
      if (path.split('/').length > 1) {
        const pathArr = path.split('/');
        return this.findParentInPath(
          pathArr.slice(0, pathArr.length - 1).join('/')
        );
      } else {
        return undefined;
      }
    } else {
      return path;
    }
  }

  isCurrentMenuHasSubItem(): boolean {
    const { containerClassnames } = this.sidebar;

    const menuItem = this.menuItems.find(
      (x) => x.to === this.selectedParentMenu
    );
    const isCurrentMenuHasSubItem = menuItem && menuItem.subs && menuItem.subs.length > 0;
    if (isCurrentMenuHasSubItem !== this.sidebar.selectedMenuHasSubItems) {
      if (!isCurrentMenuHasSubItem) {
        console.log('has submenu');
        this.sidebarService.setContainerClassnames(
          0,
          containerClassnames,
          false
        );
      } else {
        console.log('no submenu');
        this.sidebarService.setContainerClassnames(
          0,
          containerClassnames,
          true
        );
      }
    }
    return isCurrentMenuHasSubItem;
  }

  changeSelectedParentHasNoSubmenu(parentMenu: string): void {
    const { containerClassnames } = this.sidebar;
    this.selectedParentMenu = parentMenu;
    this.viewingParentMenu = parentMenu;
    this.sidebarService.changeSelectedMenuHasSubItems(false);
    this.sidebarService.setContainerClassnames(0, containerClassnames, false);
  }

  // tslint:disable-next-line:typedef
  checkHasSubmenu(item: IMenuItem) { // TODO new change
    this.haveSubmenu = item.subs && item.subs.length > 0;
    const bodyClass = document.body.classList;
    if (bodyClass.contains('desktop')) {
      bodyClass.remove('menu-collapsed');
      console.log('hit me 2')
    }
  }
  openSubMenu(event: { stopPropagation: () => void }, menuItem: IMenuItem): void {
    this.haveSubmenu = menuItem.subs && menuItem.subs.length > 0; // TODO new change

    const bodyClass = document.body.classList;
    if (bodyClass.contains('desktop')) {
      bodyClass.remove('menu-collapsed');
      console.log('hit me 3')
    }

    if (event) {
      event.stopPropagation();
    }
    const { containerClassnames, menuClickCount } = this.sidebar;

    const selectedParent = menuItem.to;
    const hasSubMenu = menuItem.subs && menuItem.subs.length > 0;
    this.sidebarService.changeSelectedMenuHasSubItems(hasSubMenu);
    if (!hasSubMenu) {
      this.viewingParentMenu = selectedParent;
      this.selectedParentMenu = selectedParent;
      this.toggle();
    } else {
      const currentClasses = containerClassnames
        ? containerClassnames.split(' ').filter((x) => x !== '')
        : '';

      if (!currentClasses.includes('menu-mobile')) {
        if (
          currentClasses.includes('menu-sub-hidden') &&
          (menuClickCount === 2 || menuClickCount === 0)
        ) {
          this.sidebarService.setContainerClassnames(
            3,
            containerClassnames,
            hasSubMenu
          );
        } else if (
          currentClasses.includes('menu-hidden') &&
          (menuClickCount === 1 || menuClickCount === 3)
        ) {
          this.sidebarService.setContainerClassnames(
            2,
            containerClassnames,
            hasSubMenu
          );
        } else if (
          currentClasses.includes('menu-default') &&
          !currentClasses.includes('menu-sub-hidden') &&
          (menuClickCount === 1 || menuClickCount === 3)
        ) {
          this.sidebarService.setContainerClassnames(
            0,
            containerClassnames,
            hasSubMenu
          );
        }
      } else {
        this.sidebarService.addContainerClassname(
          'sub-show-temporary',
          containerClassnames
        );
      }
      this.viewingParentMenu = selectedParent;
    }
  }

  toggle(): void {
    const { containerClassnames, menuClickCount } = this.sidebar;
    const currentClasses = containerClassnames
      .split(' ')
      .filter((x) => x !== '');
    if (currentClasses.includes('menu-sub-hidden') && menuClickCount === 3) {
      this.sidebarService.setContainerClassnames(
        2,
        containerClassnames,
        this.sidebar.selectedMenuHasSubItems
      );
    } else if (
      currentClasses.includes('menu-hidden') ||
      currentClasses.includes('menu-mobile')
    ) {
      if (!(menuClickCount === 1 && !this.sidebar.selectedMenuHasSubItems)) {
        this.sidebarService.setContainerClassnames(
          0,
          containerClassnames,
          this.sidebar.selectedMenuHasSubItems
        );
      }
    }
  }

  toggleCollapse(id: string): void {
    if (this.closedCollapseList.includes(id)) {
      this.closedCollapseList = this.closedCollapseList.filter((x) => x !== id);
    } else {
      this.closedCollapseList.push(id);
    }
  }

  getMenuClassesForResize(classes: string): string[] {
    let nextClasses = classes.split(' ').filter((x: string) => x !== '');
    const windowWidth = window.innerWidth;
    let isTab = this.deviceService.isTablet();
    let viewType = localStorage.getItem('viewType');

    console.log('Hit Dsd Resise');
    if (windowWidth < this.sidebarService.menuHiddenBreakpoint) {
      nextClasses.push('menu-mobile');
      this.removeClassName('menu-mobile'); // TODO new change
    } else if (windowWidth < this.sidebarService.subHiddenBreakpoint) {
      this.removeClassName('tablet'); // TODO new change
      nextClasses = nextClasses.filter((x: string) => x !== 'menu-mobile');
      if (
        nextClasses.includes('menu-default') &&
        !nextClasses.includes('menu-sub-hidden')
      ) {
        nextClasses.push('menu-sub-hidden');
      }
    } else {
      this.removeClassName('desktop'); // TODO new change
      nextClasses = nextClasses.filter((x: string) => x !== 'menu-mobile');
      if (
        nextClasses.includes('menu-default') &&
        nextClasses.includes('menu-sub-hidden')
      ) {
        nextClasses = nextClasses.filter(
          (x: string) => x !== 'menu-sub-hidden'
        );
      }
    }
    return nextClasses;
  }


  removeClassName(className: string): void {
    const body = document.querySelector('body');
    body.classList.remove('menu-mobile');
    body.classList.remove('tablet');
    body.classList.remove('desktop');
    body.classList.add(className);
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event): void {
    this.viewingParentMenu = '';
    this.selectMenu();
    this.toggle();

  }

  @HostListener('window:resize', ['$event'])
  handleWindowResize(event): void {
    if (event && !event.isTrusted) {
      return;
    }
    const { containerClassnames } = this.sidebar;
    const nextClasses = this.getMenuClassesForResize(containerClassnames);
    this.sidebarService.setContainerClassnames(
      0,
      nextClasses.join(' '),
      this.sidebar.selectedMenuHasSubItems
    );
    this.isCurrentMenuHasSubItem();
  }


  menuClicked(e: MouseEvent): void {
    e.stopPropagation();
  }


  // tslint:disable-next-line:no-shadowed-variable
  filteredMenuItems(menuItems: IMenuItem[]): IMenuItem[] {
    return menuItems
      ? menuItems.filter(
        (x) =>
          !x.roles || (x.roles && x.roles.includes(this.currentUser.role))
      )
      : [];
  }

  submenuClick(event : MouseEvent,to: string) {
    this.globalService.formNavigation(event,to)
  }

  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   this.window_Size = window.innerWidth;
  // }
  checkCalculator(label) {
    if (label === 'Portfolio Simulator') {
      const propertyCount:any = localStorage.getItem('property_count');
      return propertyCount >= 2;
    }
    else {
      return true;
    }
  }

  changeMenuVisibility(){
    this.ownPropertyCount = localStorage.getItem('property_count')?parseInt(localStorage.getItem('property_count')):0;
    this.notMyHouseCount = localStorage.getItem('not_my_house_count')?parseInt(localStorage.getItem('not_my_house_count')):0 ;
    if( this.ownPropertyCount == 0){
      this.menuItems.forEach((item) => {
        if(item.label == 'My Property'){
          item.isVisible = true;
        }
        else if(item.label == 'Learn Proppel'){
          item.isVisible = true;
        }
        else if(item.label == 'Knowledge'){
          item.isVisible = true;
        }
        else if(item.label == 'Can I Afford Calc'){
          item.isVisible = true;
        }
        else {
          item.isVisible = false;
        }
      });
    }

    if( this.notMyHouseCount >= 1){
      this.menuItems.forEach((item) => {
        if(item.label == 'EOFY Tax Report'){
          item.isVisible = true;
        }
      });
    }

    if( this.ownPropertyCount  == 1){
      this.menuItems.forEach((item) => {
        if(item.label == 'My Property'){
          item.isVisible = true;
        }
        if(item.label == 'List Properties'){
          item.isVisible = false;
        }
        else if(item.label == 'Learn Proppel'){
          item.isVisible = true;
        }
        else if(item.label == 'Knowledge'){
          item.isVisible = true;
        }
        else if(item.label == 'Can I Afford Calc'){
          item.isVisible = true;
        }
        else if(item.label == 'Comparisons'){
          item.isVisible = true;
        }
        else if(item.label == 'Sell Property'){
          item.isVisible = true;
        }
        else if(item.label == 'Interest Rate'){
          item.isVisible = true;
        }
        else {
          item.isVisible = false;
        }
      });
    }

    if( this.ownPropertyCount >= 2){
      this.menuItems.forEach((item) => {

        if(item.label == 'List Properties'){
          item.isVisible = false;
        }
        else {
          item.isVisible = true;
        }
      });
    }
  }
}
