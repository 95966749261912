import {Component, OnInit, OnDestroy, HostListener, ViewChild, Renderer2} from '@angular/core';
import { Subscription } from 'rxjs';
import { SidebarService, ISidebar } from '../sidebar/sidebar.service';
import { Router } from '@angular/router';
import { LangService, Language } from 'src/app/shared/lang.service';
import { AuthService } from 'src/app/shared/auth.service';
import { environment } from 'src/environments/environment';
// import { getThemeColor, setThemeColor } from 'src/app/utils/util';
import {GlobalService} from "../../../shared/global.service";
import {ModalDirective} from "ngx-bootstrap/modal";
import {PropertyService} from "../../../views/app/properties/property.service";
import { getThemeColor, setThemeColor, getThemeRadius, setThemeRadius } from 'src/app/utils/util';
import {NotificationsService, NotificationType} from "angular2-notifications";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.css']
})
export class TopnavComponent implements OnInit, OnDestroy {
  @ViewChild('newInDevelopmentModal') newInDevelopmentModal: ModalDirective;
  buyUrl = environment.buyUrl;
  adminRoot = environment.adminRoot;
  sidebar: ISidebar;
  subscription: Subscription;
  displayName = localStorage.getItem('full_name');
  languages: Language[];
  currentLanguage: string;
  isSingleLang;
  isFullScreen = false;
  isDarkModeActive = false;
  searchKey = '';
  profile_image = localStorage.getItem('user_profile_thumb');
  isProduction = this.globalService.getIsProduction();
  topNavHiddenItems = 0;
  notificationList:any;
  notificationCount:any;
  classicView = false;
  url:any;
  menuHalfShow = false;
  topNavMenus:any = [];
  tempTopNavMenus = [
    {
      name: 'My Documents',
      url: 'app/my-documents/documents',
      is_active: true
    },
    {
      name: 'My Suppliers',
      url: 'app/my-suppliers/suppliers',
      is_active: true
    },
    {
      name: 'My To Do\'s',
      url: 'app/my-to-do/to-do',
      is_active: true
    },
    {
      name: 'My Numbers',
      url: 'app/my-numbers',
      is_active: this.isProduction.MY_NUMBER != true
    },
    {
      name: 'Coming Soon',
      url: 'app/coming-soon',
      is_active: true
    },
    {
      name: 'Contact Us',
      url: 'app/contact-us/contact-us',
      is_active: true
    }
  ];
  topNavMenusMore = [];
  topNavMenusShow = [];
  screenSize = window.innerWidth;
  showSetting = false;
  color_picker_open = 'theme-picker';
  color_picker_close = 'theme-picker-close';
  device = false;
  ownPropertyCount = localStorage.getItem('property_count')?parseInt(localStorage.getItem('property_count')):0;
  propertyListHitCount = 0;
  constructor(
    private sidebarService: SidebarService,
    private authService: AuthService,
    private router: Router,
    private langService: LangService,
    private globalService: GlobalService,
    private propertyService: PropertyService,
    private notifications: NotificationsService,
    private renderer: Renderer2,
    public deviceService: DeviceDetectorService) {
    console.log()
    this.languages = this.langService.supportedLanguages;
    this.currentLanguage = this.langService.languageShorthand;
    this.isSingleLang = this.langService.isSingleLang;
    this.isDarkModeActive = getThemeColor().indexOf('dark') > -1 ? true : false;
    this.screenSize = window.innerWidth;
    this.getNotification();
    console.log('topnav Hit');
    this.globalService.verifyVerticalMenuItemEvent.subscribe((res) => {
      localStorage.setItem('toolbarMenu', JSON.stringify(res));
      this.createTopnavMenuVisible();
    });

this.device = this.deviceService.isTablet();
// console.log('device',this.device);
    // get current window size
    this.topNavBarMenuGenerate(window.innerWidth);
    this.changeRadius(this.radius);

    let viewType  = localStorage.getItem('viewType');
    if(this.device){
      if( viewType == null) {
        this.classicView = true;
        setTimeout(() => {
          this.topNavMenusShow = this.topNavMenus;
          this.topNavMenusMore = [];
        }, 1000);
        // this.viewChange(true);
        this.renderer.addClass(document.body, 'zoomed-in');
        localStorage.setItem('viewType', 'classic');
        this.globalService.viewMoodChange('classic');
      }
      else if(viewType == 'classic') {
        this.classicView = true;
        this.topNavMenusShow = this.topNavMenus;
        this.topNavMenusMore = [];
        // this.viewChange(true);
        this.renderer.addClass(document.body, 'zoomed-in');
        localStorage.setItem('viewType', 'classic');
        this.globalService.viewMoodChange('classic');
      }
      else {
        this.classicView = false;
        this.topNavMenusShow = [];
        this.topNavMenusMore = this.topNavMenus.slice(0);
        // this.viewChange(false);
        this.renderer.removeClass(document.body, 'zoomed-in');
        localStorage.setItem('viewType', 'default');
        this.globalService.viewMoodChange('default');
      }
    }


    this.globalService.propertyCountChangeEvent.subscribe((data)=>{
      this.ownPropertyCount = data['property_count'];
      if(this.ownPropertyCount == 0){
        this.tempTopNavMenus[0].is_active = false;
        this.tempTopNavMenus[1].is_active = false;
        this.tempTopNavMenus[2].is_active = false;
      }

      this.topNavBarMenuGenerate(window.innerWidth);

      if(this.ownPropertyCount == 0 && this.propertyListHitCount == 0){
        this.propertyListHitCount++;
        this.router.navigate(['/app/calculators/can-i-afford-calculator']);
      }
    });

    this.createTopnavMenuVisible();
  }

  onDarkModeChange(event): void {
    let color = getThemeColor();
    if (color.indexOf('dark') > -1) {
      color = color.replace('dark', 'light');
    } else if (color.indexOf('light') > -1) {
      color = color.replace('light', 'dark');
    }
    setThemeColor(color);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }

  fullScreenClick(): void {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  handleFullscreen(event): void {
    if (document.fullscreenElement) {
      this.isFullScreen = true;
    } else {
      this.isFullScreen = false;
    }
  }

  onLanguageChange(lang): void {
    this.langService.language = lang.code;
    this.currentLanguage = this.langService.languageShorthand;
  }

  async ngOnInit(): Promise<void> {
    // this.getProperty();
    // if (await this.authService.getUser()) {
    //   this.displayName = await this.authService.getUser().then((user) => {
    //     return 'Jon Doe';
    //   });
    // }
    this.subscription = this.sidebarService.getSidebar().subscribe(
      (res) => {
        this.sidebar = res;
      },
      (err) => {
      }
    );


  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  menuButtonClick = (
    e: { stopPropagation: () => void },
    menuClickCount: number,
    containerClassnames: string
  ) => {
    if (e) {
      e.stopPropagation();
    }

    setTimeout(() => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, 350);

    this.sidebarService.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.sidebar.selectedMenuHasSubItems
    );
  }


  menuCollapseClick() {
    const menuExpandIcon = this.renderer.selectRootElement('#menu-expand-icon', true);
    // check class form body have menu-expanded
    const body = document.body;
    if(!body.classList.contains('menu-collapsed')){
      // remove d-none from menu titles .menu-title
      const menuTitles = document.querySelectorAll('.menu-title');
      menuTitles.forEach((div) => {
        div.classList.remove('d-none');
      });
      // this.renderer.addClass(menuExpandIcon, 'menu-icon-none');

      body.classList.add('menu-collapsed');
    } else {
      // add d-none from menu titles .menu-title
      const menuTitles = document.querySelectorAll('.menu-title');
      menuTitles.forEach((div) => {
        div.classList.add('d-none');
      });
      body.classList.remove('menu-collapsed');
      // this.renderer.removeClass(menuExpandIcon, 'menu-icon-none');
      // this.renderer.addClass(menuExpandIcon, 'menu-icon-display');
    }


  }

  mobileMenuButtonClick = (
    event: { stopPropagation: () => void },
    containerClassnames: string
  ) => {
    if (event) {
      event.stopPropagation();
    }
    this.sidebarService.clickOnMobileMenu(containerClassnames);
  }

  deskTopMenuButtonClick = (
    event: { stopPropagation: () => void },
    containerClassnames: string,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    this.sidebarService.clickOnDeskTopMenu(containerClassnames,this.menuHalfShow);
  }

  onSignOut(): void {
    this.router.navigate(['/']);
  }

  searchKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.search();
    } else if (event.key === 'Escape') {
      const input = document.querySelector('.mobile-view');
      if (input && input.classList) {
        input.classList.remove('mobile-view');
      }
      this.searchKey = '';
    }
  }

  searchAreaClick(event): void {
    event.stopPropagation();
  }
  searchClick(event): void {
    if (window.innerWidth < environment.menuHiddenBreakpoint) {
      let elem = event.target;
      if (!event.target.classList.contains('search')) {
        if (event.target.parentElement.classList.contains('search')) {
          elem = event.target.parentElement;
        } else if (
          event.target.parentElement.parentElement.classList.contains('search')
        ) {
          elem = event.target.parentElement.parentElement;
        }
      }

      if (elem.classList.contains('mobile-view')) {
        this.search();
        elem.classList.remove('mobile-view');
      } else {
        elem.classList.add('mobile-view');
      }
    } else {
      this.search();
    }
    event.stopPropagation();
  }

  search(): void {
    if (this.searchKey && this.searchKey.length > 1) {
      this.router.navigate([this.adminRoot + '/pages/miscellaneous/search'], {
        queryParams: { key: this.searchKey.toLowerCase().trim() },
      });
      this.searchKey = '';
    }
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event): void {
    const input = document.querySelector('.mobile-view');
    if (input && input.classList) {
      input.classList.remove('mobile-view');
    }
    this.searchKey = '';

    if(event.target.id != 'color_picker'){
      if(this.showSetting == true){
        this.toggleClass = 'theme-picker-new hidden';
        this.showSetting = false
      }
    }
  }

  routerNavigateTo(appMyDoc: string) {
    this.router.navigate([appMyDoc]);
  }

  openNewInDevelopmentModal(){
    this.globalService.openNewInDevelopmentModel();
  }

  myProfile(): void  {
    this.router.navigate(['app/profile/']);
  }

  /**TODO
   * This unwanted function is added by @Janithp
   * @param event
   */
  // getProperty(){
  //   this.propertyService.getProperty().subscribe(response=>{
  //     this.propertyList = response['properties'];
  //    },error => {
  //     console.log(error);
  //   });
  // }

  // top nav use more button with screen size use window.innerWidth
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenSize = window.innerWidth;
    let windowSize = window.innerWidth;
    this.createTopnavMenuVisible();
  }

  topNavBarMenuGenerate(windowSize: any) {
    this.topNavMenus = [...this.tempTopNavMenus];
    let menu = [];
    this.topNavMenus.forEach(item=>{
      if(item.is_active == true){
        menu.push(item);
      }
    })
    console.log(menu);
console.log('menu.length',menu.length)
let hiddenItems = 0;
    if(!this.device) {
      let menuLength = menu.length;
      if (windowSize > 1400) {
        console.log('hit 1');
        hiddenItems = 0;
      } else if (windowSize > 1300) {
        console.log('hit 2');
        hiddenItems = menuLength - 5;
      } else if (windowSize > 1200) {
        console.log('hit 3');
        hiddenItems = menuLength - 4;
      } else if (windowSize > 1000) {
        console.log('hit 4');
        hiddenItems = menuLength - 3;
      } else if (windowSize > 900 ) {
        console.log('hit 5');
        hiddenItems = menuLength - 2;
      } else if (windowSize > 600) {
        console.log('hit 6');
        hiddenItems = menuLength - 1;
      } else {
        hiddenItems = 6;
      }

      console.log('this.topNavHiddenItems',hiddenItems)
      // if(menuLength > this.topNavHiddenItems){
        // console.log('hit11');
        let removeItemCount = menuLength - hiddenItems;
        // if(menu.length > this.topNavHiddenItems){
          this.topNavMenusMore = menu.slice(removeItemCount);
          this.topNavMenusShow = menu.slice(0, removeItemCount);
        // }
        // else{
        //   console.log('hit22');
        //   this.topNavMenusShow = menu;
        //   this.topNavMenusMore = [];
        // }
      // }
      // else{
      //   console.log('hit33');
      //   this.topNavMenusShow = menu;
      //   this.topNavMenusMore = [];
      // }

    }
    else {
      let viewType  = localStorage.getItem('viewType');
      if(viewType == 'classic') {
        // this.classicView = true;
        this.topNavMenusShow = menu;
        this.topNavMenusMore = [];
        // this.renderer.addClass(document.body, 'zoomed-in');
        // localStorage.setItem('viewType', 'classic');
        // this.globalService.viewMoodChange('classic');
      }
      else {
        // this.classicView = false;
        this.topNavMenusShow = [];
        this.topNavMenusMore = menu.slice(4);
        // this.renderer.removeClass(document.body, 'zoomed-in');
        // localStorage.setItem('viewType', 'default');
        // this.globalService.viewMoodChange('default');
      }
    }
  }


  colors: string[] = [
    'bluenavy',
    'blueyale',
    'blueolympic',
    'greenmoss',
    'greenlime',
    'purplemonster',
    'orangecarrot',
    'redruby',
    'yellowgranola',
    'greysteel',
  ];
  selectedColor: string = getThemeColor();
  isOpenSwitcher = false;
  toggleClass = 'theme-picker-new';
  radius = getThemeRadius();
  // constructor(private renderer: Renderer2) {
  //   this.changeRadius(this.radius);
  // }

  changeColor(color: string): void {
    this.selectedColor = color;
    setThemeColor(color);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }

  toggleSwitcher(): void {
    if(this.showSetting == true){
      this.toggleClass = 'shown theme-picker-new ';
    }
    if(this.showSetting == false){
      this.toggleClass = 'hidden theme-picker-new ';
    }

    // this.isOpenSwitcher = !this.isOpenSwitcher;
    // this.toggleClass = this.isOpenSwitcher
    //   ? 'theme-picker shown'
    //   : 'theme-picker hidden';
    // // event.stopPropagation();
  }

  changeRadius(radius): void {
    if (radius === 'flat') {
      this.renderer.removeClass(document.body, 'rounded');
    } else {
      this.renderer.addClass(document.body, 'rounded');
    }
    setThemeRadius(radius);
  }

  // @HostListener('document:click', ['$event'])
  // handleDocumentClick(event): void {
  //   this.isOpenSwitcher = false;
  //   this.toggleClass = 'theme-colors hidden';
  // }
  getNotification() {
    this.propertyService.getNotification().subscribe(res=>{
      this.notificationList = res['notification_data']
      this.notificationCount = res['notification_count']
    },error => {

    })
  }

  notificationClick(notification){
    this.propertyService.readNotification(notification.system_notification_ref).subscribe(res=>{
      if(notification.navigate_tab == 'list') {
        this.router.navigate(['app/properties/' + notification.navigate_tab]);
      }
      else{
        this.router.navigate(['app/properties/'+notification.navigate_tab+'/'+ notification.property_ref],{queryParams: { path: 'notification' , ref: notification.property_ref },});
      }
      this.getNotification();
    },error => {
      this.onError(error.message);
    });
  }

  clearAllNotifications() {
    this.propertyService.readAllNotification().subscribe(res=>{
      this.getNotification();
    },error => {
      this.onError(error.message);
    });
  }

  onSuccess(content) {
    this.notifications.create('Success', content, NotificationType.Success, { theClass: 'outline primary', timeOut: 6000, showProgressBar: true });
  }
  onError(content) {
    this.notifications.create('Error', content, NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: true });
  }

  viewChange(event) {
    console.log('event',event);
    this.topNavMenus = [...this.tempTopNavMenus];
    const appContainer = this.renderer.selectRootElement('#app-container', true);
    if(event == true) {
      this.classicView = true;
      this.topNavMenusShow = this.topNavMenus;
      this.topNavMenusMore = [];
      this.renderer.addClass(document.body, 'zoomed-in');
      localStorage.setItem('viewType', 'classic');
      this.globalService.viewMoodChange('classic');


    }
    else {
      this.classicView = false;
      this.topNavMenusMore = this.topNavMenus.slice(2);
      this.topNavMenusShow = this.topNavMenus.slice(0, 2);
      this.renderer.removeClass(document.body, 'zoomed-in');
      localStorage.setItem('viewType', 'default');
      this.globalService.viewMoodChange('default');
    }
  }

  // deskTopMenuClick($event: MouseEvent,menuClickCount,containerClassnames) {
  //   this.deskTopMenuButtonClick($event,menuClickCount, containerClassnames):;
  // }
  deskTopMenuClick($event,containerClassnames) {
    this.menuHalfShow = !this.menuHalfShow;
    this.deskTopMenuButtonClick($event,containerClassnames);
  }

   createTopnavMenuVisible() {
   setTimeout(() => {
     let res = localStorage.getItem('toolbarMenu')?JSON.parse(localStorage.getItem('toolbarMenu')):null;
     console.log(res);

     if(res != null && res != undefined){
       if(res['supplier_count']!=0){
         this.tempTopNavMenus[1].is_active = true;
       }
       else{
         this.tempTopNavMenus[1].is_active = false;
       }

       if(res['my_document_count']!=0){
         this.tempTopNavMenus[0].is_active = true;
       }
       else{
         this.tempTopNavMenus[0].is_active = false;
       }

       if(res['my_todos_count']!=0){
         this.tempTopNavMenus[2].is_active = true;
       }
       else{
         this.tempTopNavMenus[2].is_active = false;
       }
     }

     this.topNavMenus = [...this.tempTopNavMenus];
     this.screenSize = window.innerWidth;
     let windowSize = window.innerWidth;
     this.topNavBarMenuGenerate(windowSize);
   }, 1000);

  }
}
