<div cdkDropListGroup>
  <div class="row mt-10" *ngFor="let itemsRow of tableArray;" cdkDropList cdkDropListOrientation="horizontal"
       [cdkDropListData]="itemsRow" (cdkDropListDropped)="reorderDroppedItem($event)" [cdkDropListDisabled]="isDraggable">
    <div *ngFor="let row of itemsRow; let i=index" class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-4" cdkDrag>
      <div class="drag-placeholder col-xl-3 col-lg-4  col-md-6 col-sm-12 col-xs-12" *cdkDragPlaceholder></div>
      <div class="property-card" >
        <div class="image-img-div">
          <ng-container *ngIf="row.sold == 0">
            <img class="img-fluid img-res-cover cursor-pointer" [src]="row.headerImage" alt="" (click)="navigateRoute(row)">
          </ng-container>
          <ng-container *ngIf="row.sold == 1">
            <div  *ngIf="isSold">
              <img  class="img-fluid   img-res-cover  cursor-pointer" [src]="row.headerImage" alt="Image" (click)="navigateRoute(row)"/>
              <img src="../../../../../assets/img/sold-banner.png" alt="Watermark" class="watermark-image">
            </div>
          </ng-container>
        </div>
        <div class="property-card-body property-tile">
          <div class="item-badge d-flex justify-content-between mb-2 text-primary glide__slide animated" *ngIf="row.load_main"></div>
          <div class="item-badge d-flex justify-content-between mb-2 text-primary" *ngIf="!row.load_main">
            <span ><i [hidden]="row.purpose == 'Land Bank'" class="fa fa-bed"> {{row.bedrooms}}</i></span>
            <span ><i [hidden]="row.purpose == 'Land Bank'" class="fa fa-bath"> {{row.bathrooms}}</i> </span>
            <span><i class="fa fa-car"></i> {{row.car_spaces}}</span>
            <ng-container *ngIf="row.property_type == 'Apartment' ||row.property_type == 'Unit' || row.property_type == 'Commercial'">
              <span><i  class="fa fa-building-o"></i> {{row.building_size}}m<sup>2</sup></span>
            </ng-container>
            <ng-container *ngIf="row.property_type !== 'Apartment' && row.property_type !== 'Unit' && row.property_type != 'Commercial'">
              <span> {{row.land_size}}m<sup>2</sup></span>
            </ng-container>
            <!--            <div class="btn-group" dropdown placement="bottom left">-->
            <!--              <span dropdownToggle class="badge-pill badge-primary dropdown-toggle" aria-controls="menu-dropdown">-->
            <!--                <i class="glyph-icon simple-icon-options-vertical pl-1 pr-1"   style="font-size: 10px;"></i>-->
            <!--                </span>-->
            <!--              <ul id="menu-dropdown"   *dropdownMenu class="dropdown-menu dropdown-menu-left" role="menu" aria-labelledby="menu-button">-->
            <!--                <li role="menuitem"><a class="dropdown-item" href="javascript:;" (click)="hideProperty(row.property_ref)">Hide this Property</a></li>-->
            <!--                <li role="menuitem"><a class="dropdown-item" href="javascript:;" (click)="openHiddenPropertyModal()">Restore Property ({{hiddenProperties.length}})</a></li>-->
            <!--                <li role="menuitem"><a class="dropdown-item" href="javascript:;" (click)="shareProperty(row)">Share this Property</a></li>-->
            <!--                <li role="menuitem"><a class="dropdown-item" href="javascript:;" (click)="removeProperty(row.property_ref)">Remove this Property</a></li>-->
            <!--                <li role="menuitem"><a class="dropdown-item" href="javascript:;" (click)="openQuickModal(quickModel,row.property_ref)">Quick Update this Property</a></li>-->
            <!--              </ul>-->
            <!--            </div>-->
            <div class="btn-group" dropdown placement="bottom right">
              <span id="button-alignment" dropdownToggle  class="badge-pill badge-primary"
                    aria-controls="dropdown-alignment">
                <i class="glyph-icon simple-icon-options-vertical pl-1 pr-1"   style="font-size: 10px;"></i>
              </span>
              <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                  aria-labelledby="button-alignment">
                <li role="menuitem"><a class="dropdown-item" href="javascript:;" (click)="hideProperty(row.property_ref)">Hide this Property</a></li>
                <li role="menuitem"><a class="dropdown-item" href="javascript:;" (click)="openHiddenPropertyModal()">Restore Property ({{hiddenProperties.length}})</a></li>
                <li role="menuitem"><a class="dropdown-item" href="javascript:;" (click)="shareProperty(row)">Share this Property</a></li>
                <li role="menuitem"><a class="dropdown-item" href="javascript:;" (click)="removeProperty(row.property_ref)">Remove this Property</a></li>
                <li role="menuitem"><a class="dropdown-item" href="javascript:;" (click)="openQuickModal(quickModel,row.property_ref)">Quick Update this Property</a></li>
              </ul>
            </div>
          </div>
          <h5 class="font-weight-bold mb-1 cursor-pointer" (click)="navigateRoute(row)">{{row.property_address}}</h5>

          <div class="details d-flex justify-content-between mb-2 text-dark">
            <h5 *ngIf="row.purpose == null" class="font-weight-bold mb-0 "></h5>
            <h5 class="font-weight-bold mb-0 ">{{row.purpose}}</h5>
            <a href="javascript:;" *ngIf="row.is_shared_user === true"  class="badge badge-pill badge-info mb-1" (click)="shareProperty(row)">Shared</a>
<!--            <a href="javascript:;" *ngIf="(rows!= null && rows[i]['shared_users']!= undefined  && rows[i]['shared_users'].length > 0)"  class="badge badge-pill badge-info mb-1" (click)="shareProperty(row)">Shared</a>-->
          </div>

            <div class="item-badge d-flex justify-content-between mb-2 text-primary glide__slide animated" *ngIf="row.load_main"></div>
            <div class="item-badge d-flex justify-content-between mb-2 text-primary glide__slide animated" *ngIf="row.load_main"></div>
            <div class="item-badge d-flex justify-content-between mb-2 text-primary glide__slide animated" *ngIf="row.load_main"></div>

          <ng-container *ngIf="row.sold == 1 && !row.load_main">
            <div class="details d-flex justify-content-between text-dark">
              <p class="cursor-pointer" [routerLink]="['/app/properties/details/' + row.property_ref]">Purchase Price</p><p class="value-field-color cursor-pointer">${{ row.purchase_price | number:'1.2-5' }}</p>
            </div>
            <div class="details d-flex justify-content-between text-dark">
<!--              <p class="cursor-pointer" *ngIf="!isSold" [routerLink]="['/app/properties/details/' + row.property_ref]">Gain</p> <p class="value-field-color cursor-pointer" *ngIf="(row.capital_growth_percentage != null && row.capital_growth_percentage != undefined)">{{ row.capital_growth_percentage | number:'1.2-5' }}%</p>-->
              <p class="cursor-pointer" *ngIf="isSold" [routerLink]="['/app/properties/details/' + row.property_ref]">Gain</p> <p class="value-field-color cursor-pointer" *ngIf="(row.sold_capital_growth_percentage != null && row.sold_capital_growth_percentage != undefined)">{{ row.sold_capital_growth_percentage | number:'1.2-5' }}%</p>
            </div>
            <div class="details d-flex justify-content-between text-dark">
              <p class="cursor-pointer" [routerLink]="['/app/properties/details/' + row.property_ref]">Sold Price</p> <p class="value-field-color cursor-pointer" *ngIf="(row.sold_price != null && row.sold_price != undefined)">${{ row.sold_price | number:'1.2-5' }}</p>
            </div>
          </ng-container>
          <ng-container *ngIf="row.sold == 0 && !row.load_main">
            <div class="details d-flex justify-content-between text-dark">
              <p class="cursor-pointer" [routerLink]="['/app/properties/details/' + row.property_ref]">Purchase Price</p><p class="value-field-color cursor-pointer">${{ row.purchase_price | number:'1.2-5' }}</p>
            </div>
            <div class="details d-flex justify-content-between text-dark">
              <p class="cursor-pointer" [routerLink]="['/app/properties/details/' + row.property_ref]">Market Value</p><p class="value-field-color cursor-pointer">${{ row.estimated_value_now | number:'1.2-5' }}</p>
            </div>
            <div class="details d-flex justify-content-between text-dark">
              <p class="cursor-pointer" *ngIf="!isSold" [routerLink]="['/app/properties/details/' + row.property_ref]">Gain</p> <p class="value-field-color cursor-pointer" *ngIf="(row.capital_growth_percentage != null && row.capital_growth_percentage != undefined)">{{ row.capital_growth_percentage | number:'1.2-5' }}%</p>
            </div>
            <div class="details d-flex justify-content-between text-dark" *ngIf="(row.mortgage_ref != null && row.mortgage_ref != undefined)">
              <p class="cursor-pointer" [routerLink]="['/app/properties/mortgage/' + row.property_ref]">Mortgage Balance</p><p class="value-field-color cursor-pointer" *ngIf="(row.current_loan_balance != null && row.current_loan_balance != undefined)">${{ row.current_loan_balance | number:'1.2-5' }}</p>
            </div>
            <div class="details d-flex justify-content-between text-dark" *ngIf="(row.mortgage_ref == null && row.mortgage_ref == undefined)">
              <p class="cursor-pointer" [routerLink]="['/app/properties/mortgage/' + row.property_ref]">Mortgage Balance</p><p class="value-field-color cursor-pointer">No Mortgage</p>
            </div>
            <div class="details d-flex justify-content-between text-dark" *ngIf="(row.mortgage_ref != null && row.mortgage_ref != undefined)">
              <p class="cursor-pointer" [routerLink]="['/app/properties/mortgage/' + row.property_ref]">Monthly Mortgage</p><p class="value-field-color cursor-pointer" *ngIf="(row.monthly_repayments != null && row.monthly_repayments != undefined)">${{ row.monthly_repayments | number:'1.2-5' }}</p>
            </div>
            <div class="details d-flex justify-content-between text-dark" *ngIf="(row.mortgage_ref != null && row.mortgage_ref != undefined)">
              <p class="cursor-pointer" [routerLink]="['/app/properties/mortgage/' + row.property_ref]">LVR</p><p class="value-field-color cursor-pointer" *ngIf="(row.lvr != null && row.lvr != undefined)">{{ row.lvr | number:'1.2-5' }}%</p>
            </div>
            <div class="details d-flex justify-content-between text-dark">
              <p class="cursor-pointer" [routerLink]="['/app/properties/details/' + row.property_ref]">Annual Rental Income</p><p class="value-field-color cursor-pointer" *ngIf="(row.rent_per_month != null && row.rent_per_month != undefined)">${{ row.rent_per_month*12 | number:'1.2-5' }}</p>
            </div>

            <div class="details d-flex justify-content-between text-dark">
              <p class="cursor-pointer" [routerLink]="['/app/properties/mortgage/' + row.property_ref]">Monthly Income</p><p class="value-field-color cursor-pointer" *ngIf="(row.rent_per_month != null && row.rent_per_month != undefined)">${{ row.rent_per_month | number:'1.2-5' }}</p>
            </div>
            <div class="details d-flex justify-content-between text-dark">
              <p class="cursor-pointer" [routerLink]="['/app/properties/rental/' + row.property_ref]">Rental Yield</p><p class="value-field-color cursor-pointer" *ngIf="(row.rental_yield != null && row.rental_yield != undefined)"> {{ row.rental_yield | number:'1.2-5' }}%</p>
            </div>
            <div class="details d-flex justify-content-between text-dark" *ngIf="(row.mortgage_ref == null && row.mortgage_ref == undefined)">
              <p>&nbsp;</p>
            </div>
            <div class="details d-flex justify-content-between text-dark" *ngIf="(row.mortgage_ref == null && row.mortgage_ref == undefined)">
              <p>&nbsp;</p>
            </div>
          </ng-container>
          <!--          <div class="details d-flex justify-content-between text-dark mt-3 tile-description">-->
          <!--            <button *ngIf="row.is_not_rent !== 1 && row.rent_permission === 1" class="btn btn-sm btn-primary mx-1" (click)="navigateToRentPage(row.property_ref)">New Rent</button>-->

          <!--            <ng-container *ngIf="row.mortgage_ref != null">-->
          <!--              <button *ngIf="row.is_not_mortgage != 1 && row.mortgage_permission === 1" class="btn btn-sm btn-primary mx-1" (click)="navigateToMortgagePage(row.property_ref)">New Mortgage</button>-->
          <!--            </ng-container>-->

          <!--            <ng-container *ngIf="row.mortgage_ref == null">-->
          <!--              <button *ngIf="row.is_not_mortgage != 1 && row.mortgage_permission === 1" class="btn btn-sm btn-primary mx-1" (click)="navigateToMortgagePage(row.property_ref)">New Mortgage</button>-->
          <!--            </ng-container>-->

          <!--            <button *ngIf="row.expense_permission === 1" class="btn btn-sm btn-outline-primary mx-1" (click)="navigateToExpensePage(row.property_ref)">New Expense</button>-->

          <!--            <button *ngIf="row.sold == 0" class="btn btn-outline-primary mx-1"><i class="fa f-15 fa-files-o" (click)="openQuickModal(quickModel,row.property_ref)"></i></button>-->

          <!--            <div class="btn-group custom-icon pull-right" dropdown placement="top">-->
          <!--              <button id="button-alignment" dropdownToggle type="button" class="btn btn-sm btn-outline-primary mb-0 light mx-1" aria-controls="dropdown-alignment">-->
          <!--                <i class="glyph-icon simple-icon-options-vertical" style="font-size: 15px;"></i>-->
          <!--              </button>-->

          <!--              <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-alignment">-->
          <!--                <li role="menuitem mb-0"><a class="dropdown-item pb-0 pt-0" href="javascript:;" (click)="hideProperty(row.property_ref)">Hide this Property</a></li>-->
          <!--                <li role="menuitem mb-0"><a class="dropdown-item pb-0 pt-0" href="javascript:;" (click)="openHiddenPropertyModal()">Restore Property ({{hiddenProperties.length}})</a></li>-->
          <!--                <li role="menuitem mb-0"><a class="dropdown-item pb-0 pt-0" href="javascript:;" (click)="shareProperty(row)">Share this Property</a></li>-->
          <!--                <li role="menuitem mb-0"><a class="dropdown-item pb-0 pt-0" href="javascript:;" (click)="removeProperty(row.property_ref)">Remove this Property</a></li>-->
          <!--              </ul>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="row">
            <div class="details col-12 text-dark w-100">
              <button *ngIf="row.is_not_rent !== 1 && row.rent_permission === 1" type="button" class="btn btn-sm btn-primary mb-1 col-md-3"
                      (click)="navigateToRentPage(row.property_ref)">New Rent
              </button>
              <ng-container *ngIf="row.mortgage_ref != null">
                <button *ngIf="row.is_not_mortgage != 1 && row.mortgage_permission === 1" class="btn btn-sm btn-primary mb-1 col-md-4"
                        (click)="navigateToMortgagePage(row.property_ref)">New Mortgage</button>
              </ng-container>
              <ng-container *ngIf="row.mortgage_ref == null">
                <button *ngIf="row.is_not_mortgage != 1 && row.mortgage_permission === 1" class="btn btn-sm btn-primary mb-1 col-md-4"
                        (click)="navigateToMortgagePage(row.property_ref)">New Mortgage</button>
              </ng-container>
              <button *ngIf="row.expense_permission === 1" class="btn btn-sm btn-outline-primary mb-1 col-md-4"
                      (click)="navigateToExpensePage(row.property_ref)">New Expense</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>



<div bsModal #unshare_model="bs-modal" class="modal fade {{hiddenModal ? 'hidden' : ''}}" tabindex="-1"
     [config]="{ backdrop: 'static' ,ignoreBackdropClick: true}" role="dialog" aria-labelledby="dialog-sizes-name1">
  <!--unshare property-->
  <!--     [class.modal-backdrop]="isOpenShareModal">-->
  <div class="modal-dialog modal-lg">
    <div class="modal-content ">
      <div class="modal-header pb-1">
        <h4 class="modal-title pull-left">Property Shared Users</h4>
        <button type="button" class="close pull-right" (click)="closeunShareModel(unshare_model,unshareForm)"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" #unshareForm="ngForm">
          <div class="row">
            <div class="col-md-9 col-sm-9">
            </div>
            <div class="col-md-3 col-sm-3 pull-right mb-1">
              <button type="button" class="btn btn-primary btn-xs pull-right custom-btn-primary"
                      (click)="openPropertyShareModal()">Share Property
              </button>
            </div>
          </div>
          <div class="col-md-12 col-sm-12">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th scope="col pt-1"> User </th>
                  <th scope="col pt-1"> Email </th>
                  <th></th>
                </tr>
                </thead>
                <tbody *ngFor="let data of sharedProperties; index as i">
                <tr>
                  <td>
                    <span>{{data.first_name}}</span>
                  </td>
                  <td>
                    <span>{{data.email}}</span>
                  </td>
                  <td style="min-width: 176px;">
                    <div class="row">
                      <button type="button" class="btn btn-primary btn-xs mr-2 custom-btn-sec" (click)="unshareTo(unshare_model,unshareForm,data)">Unshare</button>
                      <button type="button" class="btn btn-success btn-xs custom-btn-primary" (click)="openEditSharePermissions(edit_share_model,data.permissions,data)">Edit</button>
                    </div>
                  </td>
                </tr>
                </tbody>
                <tfoot *ngIf="sharedProperties.length == 0">
                <tr>
                  <td colspan="3">No records found..</td>
                </tr>
                </tfoot>
              </table>
          </div>


        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-xs custom-btn-sec"
                (click)="closeunShareModel(unshare_model,unshareForm)">Close</button>
      </div>
    </div>
  </div>
</div>
<!--unshare property-->



<!--edit share permissions-->
<div bsModal #edit_share_model="bs-modal" class="modal fade custom-modal" tabindex="-1"
     [config]="{ backdrop: 'static' ,ignoreBackdropClick: true}" role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header pb-1">
        <h4 class="modal-title pull-left"> Edit Property Shared Permissions </h4>
        <button type="button" class="close pull-right"
                (click)="closeShareModel(edit_share_model,editShareForm);isOpenShareModal=false" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" #editShareForm="ngForm">
          <div class="col-md-12 col-sm-12">
            <div class="row ml-1">
              <div class="col-md-12">
                <div class=" form-group row mb-0">
                  <div class="col-md-2 col-2">
                    <label class="font-weight-bold text-small">User name</label>
                  </div>
                  <div class="col-md-1 col-1">
                    <span class="dots">:</span>
                  </div>
                  <div class="col-md-9 col-9">
                    <span class="text-small">{{userName}}</span>
                  </div>
                </div>
                <div class=" form-group row mb-0">
                  <div class="col-md-2 col-2">
                    <label class="font-weight-bold text-small">Email</label>
                  </div>
                  <div class="col-md-1 col-1">
                    <span class="dots">:</span>
                  </div>
                  <div class="col-md-9 col-9">
                    <span class="text-small">{{userEmail}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-sm-12">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th rowspan="1">Menu</th>
                  <th colspan="3">Permissions</th>
                </tr>
                <tr>
                  <th></th>
                  <th>View</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
                <tr>
                  <th></th>
                  <th>
                    <a *ngIf="showSelectAllView" class="cursor-pointer text-primary " href='javascript:void(0)'
                       (click)="tickAllCheckBoxViewInEdit()">Select All</a>
                    <a *ngIf="!showSelectAllView" class="cursor-pointer text-primary " href='javascript:void(0)'
                       (click)="tickAllCheckBoxViewInEdit()">Deselect All</a>

                  </th>
                  <th>
                    <a *ngIf="showSelectAllEdit" class="cursor-pointer text-primary " href='javascript:void(0)'
                       (click)="tickAllCheckBoxEditInEdit(true)">Select All</a>
                    <a *ngIf="!showSelectAllEdit" class="cursor-pointer text-primary " href='javascript:void(0)'
                       (click)="tickAllCheckBoxEditInEdit(false)">Deselect All</a>
                  </th>
                  <th>
                    <a *ngIf="showSelectAllDelete" class="cursor-pointer text-primary " href='javascript:void(0)'
                       (click)="tickAllCheckBoxDeleteInEdit(true)">Select All</a>
                    <a *ngIf="!showSelectAllDelete" class="cursor-pointer text-primary " href='javascript:void(0)'
                       (click)="tickAllCheckBoxDeleteInEdit(false)">Deselect All</a>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let data of selectedPermissionList; index as i">
                  <td>{{ data.permission_form }}</td>
                  <td >
                    <div class="custom-control custom-checkbox align-contact ">
                      <input type="checkbox" class="custom-control-input" name="is_view{{data.random}}"
                             [id]="'view_e' +i" [(ngModel)]="data.view" (click)="permissionViewClick($event.target.checked,i)">
                      <label class="custom-control-label pt-0" [for]="'view_e'+i"
                             style="color: lightslategrey!important; font-size: 95%">View</label>
                    </div>
                  </td>
                  <td>
                    <div class="custom-control custom-checkbox align-contact ">
                    <input type="checkbox" class="custom-control-input" name="is_edit{{data.random}}"
                           [id]="'edit_e'+i" [(ngModel)]="data.edit" (click)="permissionClick($event.target.checked,i)">
                    <label class="custom-control-label pt-0" [for]="'edit_e'+i"
                           style="color: lightslategrey!important; font-size: 95%">Edit</label>
                    </div>
                  </td>
                  <td>
                    <div class="custom-control custom-checkbox align-contact ">
                    <input type="checkbox" class="custom-control-input" name="is_delete{{data.random}}"
                           [id]="'delete_e'+i" [(ngModel)]="data.delete" (click)="permissionClick($event.target.checked,i)">
                    <label class="custom-control-label pt-0" [for]="'delete_e'+i"
                           style="color: lightslategrey!important; font-size: 95%">Delete</label>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <app-state-button btnClass="btn btn-primary btn-xs custom-btn-primary" [currentState]="responseWaiting" message="false"
                          (click)="shareToEdit(edit_share_model,editShareForm);isOpenShareModal=false"
                          [isDisabled]="shareButtonDisabled">Save
        </app-state-button>
        <button type="button" class="btn btn-outline-primary btn-xs custom-btn-sec"
                (click)="closeShareModel(edit_share_model,editShareForm);isOpenShareModal=false">Close</button>
      </div>
    </div>
  </div>
</div>
<!--edit share permissions-->


<div bsModal #quickModel="bs-modal" class="modal fade custom-modal" tabindex="-1"
     [config]="{ backdrop: 'static' ,ignoreBackdropClick: true}" role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header pb-1">
        <h4 class="modal-title pull-left">Quick Update - {{quickFormData.property_address}}</h4>
        <button type="button" class="close pull-right"
                (click)="quickModel.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" #quickForm="ngForm">
          <div class="row">
            <div class="col-md-8 border-right">
              <div class="form-group row">
                <label class="col-md-4 mt-1">Property Market Value</label>
                <div class="col-md-8">
                  <label class="form-group has-float-label has-left-icon-append mb-0">
                    <div class="input-group">
                      <div class="input-group-prepend custom-input-group-append-icon-right-border">
                        <span class="input-group-text custom-input-group-append-icon ">$</span>
                      </div>
                      <input type="number" class="form-control form-control-sm" name="estimated_value_now"
                             [(ngModel)]="quickFormData.estimated_value_now" #p_price="ngModel" (blur)="quickFormData.estimated_value_now = globalServie.validateNumberFormat(quickFormData.estimated_value_now)" [readOnly]="quickFormUserPermissions[0]['is_edit']!= 1">
                    </div>
                  </label>
                </div>
              </div>

              <section [hidden]="!quickFormData.has_rent">
                <hr *ngIf="quickFormData.has_rent" />
                <div class="form-group row">
                  <label class="col-md-4 mt-1">Rental Lease Start</label>
                  <div class="col-md-8">
                    <div class=" position-relative mb-0">
                      <div class="input-group" style="margin-bottom: 0px !important;">
                        <input class="form-control" bsDatepicker
                               [bsConfig]="{dateInputFormat: 'D/MM/Y',showWeekNumbers:false}"
                               [container]="'html'" name="lease_start_date"
                               [(ngModel)]="quickFormData.lease_start_date" #lease_start_date="ngModel" [disabled]="quickFormUserPermissions[1]['is_edit']!= 1">
                        <input *ngIf="quickFormUserPermissions[1]['is_edit']!= 1" class="form-control" bsDatepicker hidden
                               [bsConfig]="{dateInputFormat: 'D/MM/Y',showWeekNumbers:false}"
                               [container]="'html'" name="lease_start_date"
                               [(ngModel)]="quickFormData.lease_start_date" #lease_start_date1="ngModel">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-4 mt-1">Rental Lease Terms</label>
                  <div class="col-md-8">
                    <div class=" position-relative mb-0">
                      <div class="input-group" style="margin-bottom: 0px !important;">
                        <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                               name="terms" [(ngModel)]="quickFormData.terms" #rent_terms="ngModel" [readOnly]="quickFormUserPermissions[1]['is_edit']!= 1">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-4 mt-1">Rent Per Week</label>
                  <div class="col-md-8">
                    <label class="form-group has-float-label has-left-icon-append mb-0">
                      <div class="input-group">
                        <div class="input-group-prepend custom-input-group-append-icon-right-border">
                          <span class="input-group-text custom-input-group-append-icon ">$</span>
                        </div>
                        <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                               name="rent_per_week" [(ngModel)]="quickFormData.rent_per_week" (blur)="quickFormData.rent_per_week = globalServie.validateNumberFormat(quickFormData.rent_per_week)"
                               #per_week="ngModel" [readOnly]="quickFormUserPermissions[1]['is_edit']!= 1">
                      </div>
                    </label>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-4 mt-1">Tenant</label>
                  <div class="col-md-8">
                    <div class="position-relativemb-0">
                      <div class="input-group" style="margin-bottom: 0px !important;">
                        <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm" name="tenant"
                               [(ngModel)]="quickFormData.tenant" #tenant="ngModel" [readOnly]="quickFormUserPermissions[1]['is_edit']!= 1">
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <hr/>
              <section  [hidden]="!quickFormData.has_mortgage">
                <ng-container *ngIf="quickFormData.mortgage_type_id !=3">
                  <div class="form-group row">
                    <label class="col-md-4 mt-1">Current Loan balance</label>
                    <div class="col-md-8">
                      <label class="form-group has-float-label has-left-icon-append mb-0">
                        <div class="input-group">
                          <div class="input-group-prepend custom-input-group-append-icon-right-border">
                            <span class="input-group-text custom-input-group-append-icon ">$</span>
                          </div>
                          <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                                 name="current_loan_balance" [(ngModel)]="quickFormData.current_loan_balance"
                                 #current_bank_loan_balance="ngModel" [readOnly]="quickFormUserPermissions[2]['is_edit']!= 1" (blur)="quickFormData.current_loan_balance = globalServie.validateNumberFormat(quickFormData.current_loan_balance);calculateMonthlyRepayment(quickFormUserPermissions[2]['is_edit'],quickFormData['mortgage_type_id'],null)">
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-4 mt-1">Mortgage Interest Rate</label>
                    <div class="col-md-8">
                      <label class="form-group has-float-label has-right-icon-append">
                        <div class="input-group ">
                          <input type="number" class="form-control border-right-0" aria-describedby="inputGroup-sizing-sm"
                                 [maxLength]="2" name="loan_interest_rate"
                                 (keyup)="validateInterestRates($event.target.value)"
                                 [(ngModel)]="quickFormData.loan_interest_rate" #loan_inter="ngModel" style="padding-right: 24px;" [readOnly]="quickFormUserPermissions[2]['is_edit']!= 1"
                                 (blur)="calculateMonthlyRepayment(quickFormUserPermissions[2]['is_edit'],quickFormData['mortgage_type_id'],null)">
                          <div class="input-group-prepend ">
                              <span class="input-group-text input-group-text-right-icon custom-input-group-append-icon">
                              <i class="fa fa-percent f-12"></i></span>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-4 mt-1">Mortgage Payment (Mth)</label>
                    <div class="col-md-8">
                      <label class="form-group has-float-label has-left-icon-append mb-0">
                        <div class="input-group">
                          <div class="input-group-prepend custom-input-group-append-icon-right-border">
                            <span class="input-group-text custom-input-group-append-icon ">$</span>
                          </div>
                          <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                                 name="monthly_repayments" [(ngModel)]="quickFormData.monthly_repayments" (blur)="quickFormData.monthly_repayments = globalServie.validateNumberFormat(quickFormData.monthly_repayments)"
                                 #current_bank_loan_balance="ngModel" [readOnly]="quickFormUserPermissions[2]['is_edit']!= 1">
                        </div>
                      </label>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="quickFormData.mortgage_type_id ==3">
                  <ng-container *ngIf="quickFormData.has_split_loan">
                    <div class="form-group row">
                      <label class="col-md-4 mt-1">Current Loan balance (V)</label>
                      <div class="col-md-8">
                        <label class="form-group has-float-label has-left-icon-append mb-0">
                          <div class="input-group">
                            <div class="input-group-prepend custom-input-group-append-icon-right-border">
                              <span class="input-group-text custom-input-group-append-icon ">$</span>
                            </div>
                            <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                                   name="variable_current_loan_balance" [(ngModel)]="quickFormData.variable_current_loan_balance"
                                   #variable_current_loan_balance="ngModel" [readOnly]="quickFormUserPermissions[2]['is_edit']!= 1"
                                   (blur)="quickFormData.variable_current_loan_balance = globalServie.validateNumberFormat(quickFormData.variable_current_loan_balance);calculateMonthlyRepayment(quickFormUserPermissions[2]['is_edit'],quickFormData['mortgage_type_id'],'variable')">
                          </div>
                        </label>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4 mt-1">Interest Rate (V)</label>
                      <div class="col-md-8">
                        <label class="form-group has-float-label has-right-icon-append">
                          <div class="input-group ">
                            <input type="number" class="form-control border-right-0" aria-describedby="inputGroup-sizing-sm"
                                   [maxLength]="2" name="variable_interest_rate"
                                   (keyup)="validateInterestRates($event.target.value)"
                                   [(ngModel)]="quickFormData.variable_interest_rate" #v_loan_inter="ngModel" style="padding-right: 24px;" [readOnly]="quickFormUserPermissions[2]['is_edit']!= 1"
                                   (blur)="calculateMonthlyRepayment(quickFormUserPermissions[2]['is_edit'],quickFormData['mortgage_type_id'],'variable')">
                            <div class="input-group-prepend ">
                              <span class="input-group-text input-group-text-right-icon custom-input-group-append-icon">
                              <i class="fa fa-percent f-12"></i></span>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4 mt-1">Monthly Repayment (V)</label>
                      <div class="col-md-8">
                        <label class="form-group has-float-label has-left-icon-append mb-0">
                          <div class="input-group">
                            <div class="input-group-prepend custom-input-group-append-icon-right-border">
                              <span class="input-group-text custom-input-group-append-icon ">$</span>
                            </div>
                            <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                                   name="variable_monthly_repayment" [(ngModel)]="quickFormData.variable_monthly_repayment" (blur)="quickFormData.variable_monthly_repayment = globalServie.validateNumberFormat(quickFormData.variable_monthly_repayment)"
                                   #v_monthly_repayment="ngModel" [readOnly]="quickFormUserPermissions[2]['is_edit']!= 1">
                          </div>
                        </label>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4 mt-1">Current Loan balance (F)</label>
                      <div class="col-md-8">
                        <label class="form-group has-float-label has-left-icon-append mb-0">
                          <div class="input-group">
                            <div class="input-group-prepend custom-input-group-append-icon-right-border">
                              <span class="input-group-text custom-input-group-append-icon ">$</span>
                            </div>
                            <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                                   name="fixed_current_loan_balance" [(ngModel)]="quickFormData.fixed_current_loan_balance"
                                   #fixed_current_loan_balance="ngModel" [readOnly]="quickFormUserPermissions[2]['is_edit']!= 1"
                                   (blur)="quickFormData.fixed_current_loan_balance = globalServie.validateNumberFormat(quickFormData.fixed_current_loan_balance);calculateMonthlyRepayment(quickFormUserPermissions[2]['is_edit'],quickFormData['mortgage_type_id'],'fixed')">
                          </div>
                        </label>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4 mt-1">Interest Rate (F)</label>
                      <div class="col-md-8">
                        <label class="form-group has-float-label has-right-icon-append">
                          <div class="input-group ">
                            <input type="number" class="form-control border-right-0" aria-describedby="inputGroup-sizing-sm"
                                   [maxLength]="2" name="fixed_interest_rate"
                                   (keyup)="validateInterestRates($event.target.value)"
                                   [(ngModel)]="quickFormData.fixed_interest_rate" #f_loan_inter="ngModel" style="padding-right: 24px;" [readOnly]="quickFormUserPermissions[2]['is_edit']!= 1"
                                   (blur)="calculateMonthlyRepayment(quickFormUserPermissions[2]['is_edit'],quickFormData['mortgage_type_id'],'fixed')">
                            <div class="input-group-prepend">
                            <span class="input-group-text input-group-text-right-icon custom-input-group-append-icon">
                            <i class="fa fa-percent f-12"></i></span>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4 mt-1">Monthly Repayment (F)</label>
                      <div class="col-md-8">
                        <label class="form-group has-float-label has-left-icon-append mb-0">
                          <div class="input-group">
                            <div class="input-group-prepend custom-input-group-append-icon-right-border">
                              <span class="input-group-text custom-input-group-append-icon ">$</span>
                            </div>
                            <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                                   name="fixed_monthly_repayment" [(ngModel)]="quickFormData.fixed_monthly_repayment"
                                   #f_monthly_repayment="ngModel" [readOnly]="quickFormUserPermissions[2]['is_edit']!= 1"
                            (blur)="quickFormData.fixed_monthly_repayment = globalServie.validateNumberFormat(quickFormData.fixed_monthly_repayment)">
                          </div>
                        </label>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!quickFormData.has_split_loan">
                    <div class="form-group row">
                      <label class="col-md-4 mt-1">Current Loan balance</label>
                      <div class="col-md-8">
                        <label class="form-group has-float-label has-left-icon-append mb-0">
                          <div class="input-group">
                            <div class="input-group-prepend custom-input-group-append-icon-right-border">
                              <span class="input-group-text custom-input-group-append-icon ">$</span>
                            </div>
                            <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                                   name="current_loan_balance" [(ngModel)]="quickFormData.current_loan_balance"
                                   #current_bank_loan_balance="ngModel" [readOnly]="quickFormUserPermissions[2]['is_edit']!= 1"
                                   (blur)="quickFormData.current_loan_balance = globalServie.validateNumberFormat(quickFormData.current_loan_balance);calculateMonthlyRepayment(quickFormUserPermissions[2]['is_edit'],quickFormData['mortgage_type_id'],null)">
                          </div>
                        </label>

                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4 mt-1">Mortgage Interest Rate</label>
                      <div class="col-md-8">
                        <label class="form-group has-float-label has-right-icon-append">
                          <div class="input-group ">
                            <input type="number" class="form-control border-right-0" aria-describedby="inputGroup-sizing-sm"
                                   [maxLength]="2" name="loan_interest_rate"
                                   (keyup)="validateInterestRates($event.target.value)"
                                   [(ngModel)]="quickFormData.loan_interest_rate" #loan_inter="ngModel" style="padding-right: 24px;" [readOnly]="quickFormUserPermissions[2]['is_edit']!= 1"
                                   (blur)="calculateMonthlyRepayment(quickFormUserPermissions[2]['is_edit'],quickFormData['mortgage_type_id'],null)">
                            <div class="input-group-prepend ">
                              <span class="input-group-text input-group-text-right-icon custom-input-group-append-icon">
                              <i class="fa fa-percent f-12"></i></span>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4 mt-1">Mortgage Payment (Mth)</label>
                      <div class="col-md-8">
                        <label class="form-group has-float-label has-left-icon-append mb-0">
                          <div class="input-group">
                            <div class="input-group-prepend custom-input-group-append-icon-right-border">
                              <span class="input-group-text custom-input-group-append-icon ">$</span>
                            </div>
                            <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                                   name="monthly_repayments" [(ngModel)]="quickFormData.monthly_repayments"
                                   #current_bank_loan_balance="ngModel" [readOnly]="quickFormUserPermissions[2]['is_edit']!= 1"
                            (blur)="quickFormData.monthly_repayments = globalServie.validateNumberFormat(quickFormData.monthly_repayments)">
                          </div>
                        </label>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </section>
              <!--              </ng-container>-->
            </div>
            <div class="col-md-4">
              <h4>Go To...</h4>
              <ul class="list-unstyled mb-5 menu-scroll" *ngIf="quickFormUserPermissions.length > 0">
                <li class="active pb-10 mb-0" *ngIf="quickFormUserPermissions[0]['is_view'] === 1">
                  <!--                  <a class="f-16" href="javascript:;" (click)="menuClick($event,'/app/properties/details/'+quickFormData.property_ref)">-->
                  <!--                    Property Detail</a>-->
                  <a class="f-16" href="javascript:;" (click)="menuClick($event,'/app/properties/details')">
                    Property Detail</a>
                </li>
                <li class="pb-10 mb-0"   *ngIf="quickFormUserPermissions[1]['is_view'] === 1">
                  <a class="f-16" href="javascript:;" (click)="menuClick($event,'/app/properties/rental')">
                    Rent </a>
                </li>
                <li class="pb-10 mb-0"  *ngIf="quickFormUserPermissions[2]['is_view'] === 1" >
                  <a class="f-16" href="javascript:;" (click)="menuClick($event,'/app/properties/mortgage')">
                    Mortgage </a>
                </li>
                <li class="pb-10 mb-0" *ngIf="quickFormUserPermissions[3]['is_view'] === 1">
                  <a  class="f-16" href="javascript:;" (click)="menuClick($event,'/app/properties/expense')">
                    Expenses</a>
                </li>
                <li class="pb-10 mb-0" *ngIf="quickFormUserPermissions[4]['is_view'] === 1">
                  <a class="f-16" href="javascript:;" (click)="menuClick($event,'/app/properties/documents-new')">
                    Documents </a>
                </li>
                <li class="pb-10 mb-0"  *ngIf="quickFormUserPermissions[12]['is_view'] === 1">
                  <a class="f-16" href="javascript:;" (click)="menuClick($event,'/app/properties/assets-details')">
                    Assets </a>
                </li>
                <li class="pb-10 mb-0"  *ngIf="quickFormUserPermissions[5]['is_view'] === 1">
                  <a class="f-16" href="javascript:;" (click)="menuClick($event,'/app/properties/depreciation')">
                    Depreciation</a>
                </li>
                <li class="pb-10 mb-0"  *ngIf="quickFormUserPermissions[6]['is_view'] === 1">
                  <a class="f-16" href="javascript:;" (click)="menuClick($event,'/app/properties/property-issues')">
                    Issues</a>
                </li>
                <li class="pb-10 mb-0"  *ngIf="quickFormUserPermissions[7]['is_view'] === 1">
                  <a class="f-16" href="javascript:;" (click)="menuClick($event,'/app/properties/property-insurances')">
                    Insurances </a>
                </li>
                <li class="pb-10 mb-0"  *ngIf="quickFormUserPermissions[8]['is_view'] === 1" >
                  <a class="f-16" href="javascript:;" (click)="menuClick($event,'/app/properties/photos')">
                    Pictures </a>
                </li>
                <li class="pb-10 mb-0"  *ngIf="quickFormUserPermissions[9]['is_view'] === 1">
                  <a class="f-16" href="javascript:;" (click)="menuClick($event,'/app/properties/suppliers')">
                    Suppliers</a>
                </li>
                <li class="pb-10 mb-0" *ngIf="quickFormUserPermissions[10]['is_view'] === 1">
                  <a class="f-16" href="javascript:;" (click)="menuClick($event,'/app/properties/property-todo-list')">
                    To-do List</a>
                </li>

                <li class="pb-10 mb-0"  *ngIf="quickFormUserPermissions[11]['is_view'] === 1" >
                  <a class="f-16" href="javascript:;" (click)="menuClick($event,'/app/properties/property-other-info')">
                    Other Info</a>
                </li>
              </ul>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <app-state-button btnClass="btn btn-primary btn-xs custom-btn-primary" [currentState]="responseWaiting" message="false"
                          [isDisabled]="quickFormButtonDisabled" (click)="quickFormSubmit(quickForm,quickModel)">Save
        </app-state-button>
        <button type="button" class="btn btn-outline-primary btn-xs custom-btn-sec"
                (click)="quickModel.hide()">Close</button>
      </div>
    </div>
  </div>
</div>
